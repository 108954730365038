import React from 'react'
import ReactDOM from 'react-dom'
import './index.sass'
import "moment/locale/de"
import App from './App.jsx'
import * as serviceWorker from './serviceWorker'
import { library } from "@fortawesome/fontawesome-svg-core"
import { far } from "@fortawesome/pro-regular-svg-icons"

library.add(far)

// TODO: Check whether we still need react-textfit and remove if not needed anymore

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
