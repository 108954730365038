import React from "react"
import styled from "styled-components"
import invitationImage from "../static/invitation.jpg"
import reuthColors from "../static/reuthColors"

function Invitation(){
  return (
    <StyledDiv>
      <StyledImg src={invitationImage} alt="Einladung zu unserem Geburtstag." />
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  background-color: ${reuthColors.background};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`

const StyledImg = styled.img`
  height: 90vh;
`

export default Invitation