import React from 'react'
import {Doughnut} from 'react-chartjs-2'
import styled from "styled-components"
import reuthColors from "../static/reuthColors"

const dataTemplate = {
    datasets: [{
        label: 'My First Dataset',  // TODO: Delete if safe to delete
        data: [52, 48],  // Set by showrunner
        backgroundColor: [  // TODO: Make customizable by showrunner
            reuthColors.green,
            reuthColors.orange,
        ],
        hoverOffset: 4
    }]
}

const optionsTemplate = {
    plugins: {
        legend: {
            display: false
        },
        title: {
            display: true,
            color: "white",
            text: "Produktivität KW 22",  // Set by showrunner
            padding: 24,
            font: {
                size: 48
            }
        },
    }
}

function VerticalBar({values, title}) {
    let data = {...dataTemplate}
    data.datasets[0].data = values
    let options = {...optionsTemplate}
    options.plugins.title.text = title

    return (
        <StyledDiv>
            <Doughnut data={data} options={options} />
        </StyledDiv>
    )
}

export default VerticalBar

const StyledDiv = styled.div`
  position: relative;
  width: 75%;
`
