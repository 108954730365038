const stickData = {
    "logo": "1ety2ehbphpPyj2DRuhm",
    "updatedby": "thomas.lenz@haleos.de",
    "lastupdate": {
        "seconds": 1623653627,
        "nanoseconds": 454000000
    },
    "id": "38bb8e8f",
    "sequence": "RSudKnncFpy568uUrNL0",
    "name": "Halle 1",
    "mode": "debug"
}

const seqData = {
    "blocks": [
        {
            "logo": "bottom-right",
            "duration": 30,
            "data": {
                "line": {
                    "labels": [
                        "KW 15",
                        "KW 16",
                        "KW 17",
                        "KW 18",
                        "KW 19",
                        "KW 20",
                        "KW 21",
                        "KW 22",
                        "KW 23",
                        "KW 24"
                    ],
                    "data": {
                        "spindellaufzeit": [
                            "171",
                            "161",
                            "188",
                            "211",
                            "147",
                            "167",
                            "157",
                            "142",
                            "227",
                            "247"
                        ],
                        "anwesenheit": [
                            "389",
                            "372",
                            "401",
                            "440",
                            "295",
                            "367",
                            "253",
                            "271",
                            "359",
                            "347"
                        ]
                    }
                },
                "doughnut": {
                    "title": "KW 24",
                    "data": [
                        71,
                        29
                    ]
                },
                "bar": {
                    "data": [
                        44,
                        43,
                        47,
                        48,
                        50,
                        46,
                        62,
                        52,
                        63,
                        71
                    ],
                    "labels": [
                        "KW 15",
                        "KW 16",
                        "KW 17",
                        "KW 18",
                        "KW 19",
                        "KW 20",
                        "KW 21",
                        "KW 22",
                        "KW 23",
                        "KW 24"
                    ]
                }
            },
            "type": "reuth1"
        },
        {
            "duration": 5,
            "logo": "centered",
            "type": "time",
            "data": {
                "timezone": "Europe/Berlin"
            }
        },
        {
            "duration": 30,
            "data": {
                "content": [
                    "Ab dem 01.07.2021 unterstützt uns Klaus Merzbach in der Produktion als Teilzeitkraft.",
                    "Im Juli werden wir die Ausbildungsmaschine gegen eine modernere Mazak VCN 410 tauschen."
                ]
            },
            "logo": "bottom-right",
            "type": "announcement"
        }
    ],
    "id": "RSudKnncFpy568uUrNL0",
    "plan": {},
    "updatedby": "alexandra.boehm@reuth.de",
    "lastupdate": {
        "seconds": 1624348340,
        "nanoseconds": 767000000
    }
}

const offlineMockData = {
    stickData: stickData,
    seqData: seqData
}

export default offlineMockData
