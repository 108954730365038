import {createGlobalStyle} from "styled-components"

import LexendMedium from "./Lexend-Medium.ttf"
import RobotoMedium from "./Roboto-Medium.ttf"

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: "Lexend";
        src: local("LexendMedium"), url(${LexendMedium}) format("truetype");
        font-weight: 500;
        font-style: normal;
    }
    @font-face {
        font-family: "Roboto";
        src: local("RobotoMedium"), url(${RobotoMedium}) format("truetype");
        font-weight: 500;
        font-style: normal;
    }
`

export default GlobalStyle
