import React, {useContext} from "react"
import styled from "styled-components"
import reuthColors from "../../static/reuthColors"
import ShowRunnerContext from "../../utils/ShowrunnerContext"
import ConfigContext from "../../utils/ConfigContext"

// TODO: Handle no announcement scenario

export default function Announcement() {
    const SR = useContext(ShowRunnerContext)
    const announcements = SR.data.content

    if (announcements.length > 1) {
        const listElements: JSX.Element[] = []
        announcements.forEach((element: string, index: number) => {
            listElements.push(<li key={index}>{element}</li>)
        })
        return (
            <AnnouncementWrapper logo={SR.logo && true} mult>
                <ul>{listElements}</ul>
            </AnnouncementWrapper>
        )
    }

    // One Announcement
    return (
        <AnnouncementWrapper logo={SR.logo && true}>
            <p>{announcements[0]}</p>
        </AnnouncementWrapper>
    )
}

const AnnouncementWrapper = (props: {children?: React.ReactNode, mult?: boolean, logo?: boolean}) => {
    const CONFIG = useContext(ConfigContext)

    return (
        <Bg>
            {props.mult ?

                // Multiple Announcements
                <Wrapper>
                    <h1>Ank&uuml;ndigungen</h1>
                    {props.children}
                </Wrapper>

                :

                // One Announcement
                <WrapperWithCenteredAnnouncement>
                    <h1>Ank&uuml;ndigung</h1>
                    {props.children}
                </WrapperWithCenteredAnnouncement>

            }

            <Subtitle>Alle News findet ihr auch in unserem Intranet!</Subtitle>
            {props.logo && <FixedLogo src={CONFIG.logoUrl} alt="Logo"/> }
        </Bg>

    )
}

const Bg = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${reuthColors.background};
  color: ${reuthColors.text};
`

const Wrapper = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;

  > h1 {
    margin: 0;
    font-size: 5em
  }

  > ul {
    font-size: 5em;
    margin: 5rem 2rem 2rem 1rem;

    > li {
      padding: 0.5em;
    }
  }
`

const WrapperWithCenteredAnnouncement = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > h1 {
    margin-top: 2rem;
    font-size: 5em;
    justify-self: flex-start;
  }

  > p {
    padding: 2rem;
    font-size: 4em;
    text-align: center;
  }
`

const FixedLogo = styled.img`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  height: 12.5vh;
`

const Subtitle = styled.h2`
  position: fixed;
  bottom: 0;
  font-size: 3rem;
  display: flex;
  justify-content: center;
  width: 100vw;
`