import React from "react"
import "./style.sass"

export default function News(props?: {title: string, paper?: string, imageRef?: string, imageCopyright?: string, content1: string, content2?: string}) {
    // TODO: Block 1 und Block 2 verbinden, dass der Text vom einen in den anderen geschrieben wird
    //  NICHT mehr mit Content1 und Content2 arbeiten

    let title = "Der Aufschwung digitaler Bildung"
    let paper = "The New Yorker"
    let imageRef = "https://cs.harvard.edu/malan/malan.jpg"
    let imageCopyright = "David Malan / Harvard University"
    let content1 = "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet"
    let content2 = "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet"

    return (
        <section id="News" className="wrapper">
            <div className="headertable">
                <table className="header">
                    <tr>
                        <td className="tau">
                            <span className="color">
                                TAU
                            </span>
                            -News
                        </td>
                        <td className="paper">
                            {paper}
                        </td>
                    </tr>
                </table>
            </div>
            <article>
                <div className="artikel">
                    <p className="title color">
                        {title}
                    </p>
                    <p className="image">
                        <img src={imageRef} alt="Artikel-Foto" className="img">
                        </img>
                        <small>
                            {imageCopyright}
                        </small>
                    </p>
                    <p className="block1">
                        {content1}
                    </p>

                    <p className={"block2"}>
                        {content2}
                    </p>
                </div>
            </article>


        </section>
    )
}
