import React from "react"
import { Line } from 'react-chartjs-2';


export default function LineChart(props) {
    let data = {
        labels: props.content.data.labels,
        datasets: [{
            label: props.content.title,
            data: props.content.data.values,
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
        }]
    }

    let options = {
        legend: {
            display: true
        },
        animation: {
            animations: {
                tension: {
                    duration: 1000,
                    easing: 'linear',
                    from: 1,
                    to: 0,
                    loop: true,
                    delay: 5000
                }
            }
        },
        ...(props.content.title &&
            {
                title: {
                    display: true,
                    text: props.content.subtitle ? [props.content.title, props.content.subtitle] : props.content.title
                }
            }
        )
    }

    return <Line data={data} options={options} />
}