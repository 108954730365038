import React from "react"
import styled from "styled-components"
import fcb from "../static/fcb.jpeg"
import reuthColors from "../static/reuthColors"

function FCB(){
    return (
        <StyledDiv>
            <StyledImg src={fcb} alt="FC Bayern ist deutscher Meister!" />
            <StyledWrapper>
                <StyledH1>FC Bayern Deutscher Meister!</StyledH1>
            </StyledWrapper>
            {/* <Logo />
            <FixedLogo src={Logo} alt="Logo"/>*/}
        </StyledDiv>
    )
}

const StyledDiv = styled.div`
  background-color: ${reuthColors.background};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`

const StyledImg = styled.img`
  height: 90vh;
`

const StyledH1 = styled.h1`
  z-index: 2;
  color: white;
  font-size: 6rem;
  width: 100vw;
  transform: rotate(-30deg);

`

const StyledWrapper = styled.div`
  z-index: 100;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-40%, -125%);
`

const FixedLogo = styled.img`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  height: 12.5vh;
`

export default FCB