import React from "react"
import styled from "styled-components"
import {TwitterTweetEmbed} from "react-twitter-embed"

export default function Twitter() {
    return (
        <TwitterSection id="Twitter">
            <TwitterTweetEmbed
                tweetId={'20'}
                options={{height: 400}}
            />
        </TwitterSection>
    )
}

const TwitterSection = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
`
