import React from 'react'
import { Bar } from 'react-chartjs-2'
import styled from "styled-components"
import reuthColors from "../static/reuthColors"

/*

Zweites von Rechts: Produktivität

10;451;262;312;574;58%;42%;
11;462;274;295;569;59%;41%;
12;454;254;306;560;56%;44%;
13;323;224;309;533;69%;31%;
14;302;174;266;440;58%;42%;
15;389;171;274;445;44%;56%;(ohne Hermle + Detlef)
16;372;161;298;459;43%;57%;""""
17;401;188;273;461;47%;53%;""""
18;440;211;261;472;48%;52%;
19;295;147;198;345;50%;50%;
20;367;167;229;396;46%;54%;
21;               ;62%;
22;               ;52%;

*/

const dataTemplate = {
    labels: undefined,  // Set by showrunner
    datasets: [
        {
            label: 'Produktivität',
            data: undefined,  // Set by showrunner
            backgroundColor: [
                reuthColors.orangeLight,
                reuthColors.orangeLight,
                reuthColors.orangeLight,
                reuthColors.orangeLight,
                reuthColors.orangeLight,
                reuthColors.orangeLight,
                reuthColors.orangeLight,
                reuthColors.orangeLight,
                reuthColors.orangeLight,
                reuthColors.orangeLight,
            ],
            borderColor: [
                reuthColors.orange,
                reuthColors.orange,
                reuthColors.orange,
                reuthColors.orange,
                reuthColors.orange,
                reuthColors.orange,
                reuthColors.orange,
                reuthColors.orange,
                reuthColors.orange,
                reuthColors.orange,
            ],
            borderWidth: 1,
        },
    ],
}

const optionsTemplate = {
    scales: {
        yAxes: {
            min: 0,
            ticks: {
                color: "white"
            },
            title: {
                display: true,
                text: "Stunden",
                color: "white",
                font: {
                    size: 36
                }
            }
        },
        xAxes: {
            ticks: {
                color: "white"
            },
        }
    },

    plugins: {

        legend: {
            position: "right",
            labels: {
                color: "white",
                font: {
                    size: 24,
                }
            }
        },

        title: {
            display: true,
            color: "white",
            text: ["Produktivität", "- Verhältnis Anwesenheitsstunden zu Spindellaufzeit -"],
            padding: 24,
            font: {
                size: 48
            }
        }
    }
}

function VerticalBar({labels, values}) {
    let data = {...dataTemplate}
    data.labels = labels
    data.datasets[0].data = values
    const options = {...optionsTemplate}

    return (
        <StyledDiv>
            <Bar
                data={data}
                options={options}
            />
        </StyledDiv>
    )
}

export default VerticalBar

const StyledDiv = styled.div`
  position: relative;
  width: 80%;
`