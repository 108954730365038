import React, {useContext} from "react"
import Unsplash from "react-unsplash-wrapper"
import styled from "styled-components"
import ConfigContext from "../../utils/ConfigContext"

// TODO: Restructure props

/*

Right now, we are expecting type, keywords and backgroundColor as props. Keyword is only
used, and therefore only _really_ expected, when "logo" is passed as type whereas back-
groundColor is only used(/expected) when "wallpaper" is passed. Even then, when the
adequate type is selected, the additional parameters are optional and the view will
result in a purposeful outcome. Maybe there is a better way to do this, but I don't see
it at the moment and don't have the time to overthink it.
-tommylenz

*/

interface screensaverType {
    type: "logo" | "wallpaper",
    keywords?: string,
    backgroundColor?: string
}

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  background-color: ${props => props.color ? props.color : null};
  align-items: center;
  justify-content: center;
  > img {
    height: 50vh;
  }
`

export default function Screensaver(props: screensaverType): any {
    const CONFIG = useContext(ConfigContext)
    if (props.type === "logo") {
        return <Wrapper color={props.backgroundColor}><img src={CONFIG.logoUrl} alt="Logo" /></Wrapper>
    }

    if (props.type === "wallpaper") {
        if (props.keywords) {
            return <Unsplash keywords={props.keywords} expand />
        }
        return <Unsplash expand />
    }
}