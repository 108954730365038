import React from "react"
import styled from "styled-components"

export default function Blackscreen() {
    return (
        <StyledSection>
        </StyledSection>
    )
}

const StyledSection = styled.section`
    background-color: black;    
`