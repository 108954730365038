import React from 'react'
import { Line } from 'react-chartjs-2'
import reuthColors from "../static/reuthColors"

/*
KW - Anwesenheit Mitarbeiter - Spindellaufzeit

11;462;274;295;569;59%;41%;
12;454;254;306;560;56%;44%;
13;323;224;309;533;69%;31%;
14;302;174;266;440;58%;42%;
15;389;171;274;445;44%;56%;(ohne Hermle + Detlef)
16;372;161;298;459;43%;57%;""""
17;401;188;273;461;47%;53%;""""
18;440;211;261;472;48%;52%;
19;295;147;
20;367;167;
21;253;157;
22;271;142;

*/

const dataTemplate = {
    labels: ['KW 13', 'KW 14', 'KW 15', 'KW 16', 'KW 17', 'KW 18', 'KW 19', 'KW 20', 'KW 21', 'KW 22'],
    datasets: [
        {
            label: 'Anwesenheit',
            data: [323,302,389,372,401,440, 295, 367, 253, 271],
            fill: false,
            backgroundColor: reuthColors.orange,
            borderColor: reuthColors.orangeLight,
            borderWidth: 7.5,
            pointRadius: 5,
        },
        {
            label: 'Spindellaufzeit',
            data: [224, 174, 171, 161, 188, 211, 147, 167, 157, 142],
            fill: false,
            backgroundColor: reuthColors.green,
            borderColor: reuthColors.greenLight,
            borderWidth: 7.5,
            pointRadius: 5,
        },
    ],
}

const optionsTemplate = {
    scales: {
        yAxes: {
            min: 0,
            ticks: {
                color: "white"
            },
            title: {
                display: true,
                text: "Stunden",
                color: "white",
                font: {
                    size: 36
                }
            }
        },
        xAxes: {
            ticks: {
                color: "white"
            },
        }
    },

    datasets: {
        line: {
            borderWidth: 5,
        }
    },

    plugins: {

        legend: {
            position: "right",
            labels: {
                color: "white",
                font: {
                    size: 24,
                }
            }
        },

        title: {
            display: true,
            color: "white",
            text: "Übersicht OEE",
            padding: 24,
            font: {
                size: 48
            }
        },

        datalabels: {
            color: "#FFCE56"
        }
    },

    // Responsive behaviour
    maintainAspectRatio: false,
}

function LineChart({labels, values}) {
    let data = {...dataTemplate}
    data.labels = labels
    // datasets[0] is Anwesenheit, datasets[1] is Spindellaufzeit
    data.datasets[0].data = values.anwesenheit
    data.datasets[1].data = values.spindellaufzeit
    let options = {...optionsTemplate}

    return (
        <Line data={data} options={options}/>
    )
}

export default LineChart