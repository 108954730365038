import React from "react"

// Views
import Announcement from "../views/Announcement"
import Blackscreen from "../views/Blackscreen"
import Countdown from "../views/Countdown"
import Debug from "../views/Debug"
import DrawANumber from "../views/DrawANumber"
import Emergency from "../views/Emergency"
import Image from "../views/Image"
import News from "../views/News"
import Notice from "../views/Notice"
import Plan from "../views/Plan"
import Screensaver from "../views/Screensaver"
import Ticker from "../views/Ticker"
import Time from "../views/Time"
import Twitter from "../views/Twitter"
import Video from "../views/Video"
import Weather from "../views/Weather"
// Custom Views
import Reuth1 from "../customViews/Reuth1"
import FCB from "../customViews/FCB"
import Invitation from "../customViews/ReuthBirthdayInvitation"
// Components
import DoughnutChart from "../components/doughnutChart"
import LineChart from "../components/lineChart"

interface buildInstructions {
    type: string,
    duration: number,
    content?: string | any[]
}

const Components: {[index: string]: any} = {
    "announcement": Announcement,
    "blackscreen": Blackscreen,
    "countdown": Countdown,
    "debug": Debug,
    "drawANumber": DrawANumber,
    "emergency": Emergency,
    "image": Image,
    "news": News,
    "notice": Notice,
    "plan": Plan,
    "screensaver": Screensaver,
    "ticker": Ticker,
    "time": Time,
    "twitter": Twitter,
    "video": Video,
    "weather": Weather,

    // Custom Views
    "reuth1": Reuth1,
    "fcb": FCB,
    "invitation": Invitation,

    // Components
    "doughnutChart": DoughnutChart,
    "lineChart": LineChart
}

/**
 * Generates react elements (nodes) to be displayed
 * @param apiResponse - fetched response from billboard endpoint
 *
 * @alpha
 */

function generateTreeNode(apiResponse: buildInstructions[]) {
    let nodes: JSX.Element[] = []
    apiResponse.forEach((element, index) => {
        nodes.push(React.createElement(Components[element.type]))
    })
    return nodes
}

export default generateTreeNode