import React, {useContext} from "react"
import styled from "styled-components"
import reuthColors from "../static/reuthColors"

import "chartjs-plugin-datalabels"
import DoughnutChart from "../dummy/DoughnutChart"
import BarChart from "../dummy/BarChart"
import LineChart from "../dummy/LineChart"
import ShowRunnerContext from "../utils/ShowrunnerContext"
import ConfigContext from "../utils/ConfigContext"

export default function Reuth1() {
    const CONFIG = useContext(ConfigContext)
    const SR = useContext(ShowRunnerContext)

    return (
            <Grid>
                {/* Übersicht OEE */}
                <UpperChart>
                    <LineChart
                        labels={SR.data.line.labels}
                        values={SR.data.line.data}
                    />
                </UpperChart>

                {/* Produktivität */}
                <LowerLeftChart>
                    <BarChart
                        labels={SR.data.bar.labels}
                        values={SR.data.bar.data}
                    />
                </LowerLeftChart>

                {/* Produktivität der KW */}
                <LowerRightChart>
                    <DoughnutChart
                        values={SR.data.doughnut.data}
                        title={SR.data.doughnut.title}
                    />
                </LowerRightChart>

                {/* <Logo /> */}
                <FixedLogo src={CONFIG.logoUrl} alt="Logo"/>
            </Grid>
    )
}

const UpperChart = styled.div`
  grid-area: upper;
  display: flex;
  padding: 1rem 2rem;
  justify-content: center;
`

const LowerLeftChart = styled.div`
  grid-area: lowerLeft;
  display: flex;
  padding: 1rem 2rem;
  justify-content: center;
  
  > canvas {
    height: 100% !important;
  }
`

const LowerRightChart = styled.div`
  grid-area: lowerRight;
  display: flex;
  padding: 1rem 2rem;
  justify-content: center;
`

const Grid = styled.main`
  display: grid;
  height: 100vh;
  width: 100vw;
  background-color: ${reuthColors.orange};
  > * {
    margin: 0;
    background-color: ${reuthColors.background};
  }
  color: white;
  grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr) minmax(0, 1fr);
  row-gap: 1rem;
  grid-template-areas:
    "upper upper"
    "lowerLeft lowerRight";

`

const FixedLogo = styled.img`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  height: 12.5vh;
`