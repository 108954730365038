const reuthColors = {
    orange: "#EF7900",
    orangeLight: "#EF790066",
    grey: "#6B6A6A",
    greyLight: "#6B6A6A33",
    green: "#4D8B31",
    greenLight: "#4D8B3166",

    background: "#888",
    text: "#fff",
}

export default reuthColors