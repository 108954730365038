import React from 'react'
import styled from "styled-components"
import FitText from "@kennethormandy/react-fittext"
import Moment from "react-moment"

export default function Countdown(props: {content: string}) {
    return (
        <StyledSection>
            <StyledMoment locale="de" date="2021-06-19T13:25:00+02:00" fromNow />
            <FitText>{props.content}</FitText>
        </StyledSection>
    )
}

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 5rem;
  font-family: Roboto, sans-serif;
`

const StyledMoment = styled(Moment)`
  &:after {
    content: ":";
  }
`