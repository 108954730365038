import React from "react"
import JSONPretty from "react-json-pretty"
import "react-json-pretty/themes/monikai.css"
import ConfigContext from "../../utils/ConfigContext"
import styled from "styled-components";

function Debug() {
    const data = React.useContext(ConfigContext)
    return (
        <StyledDiv>
            <JSONPretty data={data.stickData} />
            <JSONPretty data={data.seqData} />
        </StyledDiv>
    )
}

export default Debug

const StyledDiv = styled.div`
  display: flex;
  > div {
    > pre {
      margin: 0;
    }
  }
`