import React, {useEffect} from 'react'
import {useDebugState} from "use-named-state"

import GlobalFonts from "./static/fonts/fonts"
import ConfigContext from "./utils/ConfigContext"
import ShowRunnerContext from "./utils/ShowrunnerContext"
import generateNodes from "./utils/generateNodes"
import {doc, onSnapshot} from "firebase/firestore"
import {db, storage} from "./utils/firebase"
import {getStorage, ref, getDownloadURL} from "firebase/storage"
import offlineMockData from "./utils/offlineMockData"

function App() {
    const [progress, setProgress] = useDebugState("progress", {})
    const [displayElements, setDisplayElements] = useDebugState("displayElements", [])
    const [config, setConfig] = useDebugState("config", {})

    const stickId = "38bb8e8f"
    const offlineMode = process.env.REACT_APP_OFFLINE

    useEffect(() => {
        if (!offlineMode) {
            return onSnapshot(doc(db, "smt", "bb", "sticks", stickId), (doc) => {
                if (!doc.exists()) throw new Error("Stick doc doesn't exist")  // TODO: Error handling
                // Obtain logo url, if logo exists
                const storage = getStorage()
                const logoRef = ref(storage, "userdata/" + doc.data().logo + "/logo.png")
                getDownloadURL(logoRef)  // TODO: Error handling
                    .then((url) => {
                        // Write stickData and logoUrl to config
                        setConfig(prevState => ({
                            ...prevState,
                            stickData: doc.data(),
                            logoUrl: url
                        }))
                    })
            })
        } else {
            setConfig(prevState => ({
                ...prevState,
                stickData: offlineMockData.stickData,
                logoUrl: ""
            }))
        }
    }, [stickId])

    useEffect(() => {
        if (config.stickData) {
            if (!offlineMode) {
                return onSnapshot(doc(db, "smt", "bb", "sequences", config.stickData.sequence), (doc) => {
                    if (!doc.exists()) throw new Error("Sequence doc doesn't exist")  // sanity check
                    setConfig(prevState => ({...prevState, seqData: doc.data()}))
                })
            } else {
                setConfig(prevState => ({...prevState, seqData: offlineMockData.seqData}))
            }
        }
    }, [config.stickData])

    useEffect(() => {
        if (config.seqData) {
            try {
                if (config.stickData.mode === "debug") {
                    setDisplayElements(generateNodes([{type: "debug"}]))
                } else if (config.stickData.mode === "identify") {
                    setDisplayElements(generateNodes([{type: "identify"}]))
                } else {
                    setDisplayElements(generateNodes(config.seqData.blocks))
                }
                let durationsOfBlocks = []
                config.seqData.blocks.forEach((block) => {
                    durationsOfBlocks.push(block.duration)
                })
                setProgress({screen: 0, ticks: 0, durations: durationsOfBlocks})
            } catch(error) {
                // TODO: Error handling
                // e.g. Firestore error (Could not reach Cloud Firestore backend)
                console.log(error)
            }
        }
    }, [config.seqData])

    useEffect(() => {
        if (displayElements.length && Object.entries(progress).length !== 0) {
            let interval = setInterval(() => {
                if (progress.ticks === progress.durations[progress.screen]) {
                    if (progress.screen === displayElements.length - 1) {
                        // Currently: Last Screen, Next: First Screen
                        setProgress(prevState => {
                            return {...prevState, screen: 0, ticks: 0}
                        })
                    } else {
                        // Currently: Screen n, Next: Screen n+1
                        setProgress(prevState => {
                            return {...prevState, screen: prevState.screen + 1, ticks: 0}
                        })
                    }
                } else {
                    // No screen change
                    setProgress(prevState => {return { ...prevState, ticks: prevState.ticks + 1 }})
                }
            }, 1000)
            return () => clearInterval(interval)
        }
    }, [displayElements, progress, config])

    if (!displayElements || !progress || !config.seqData) return <p>Fetching data ...</p>  // TODO: Implement loading screen

    return (
        <ConfigContext.Provider value={config}>
            <ShowRunnerContext.Provider value={config.seqData.blocks[progress.screen]}>
                <GlobalFonts />
                {displayElements[progress.screen]}
            </ShowRunnerContext.Provider>
        </ConfigContext.Provider>
    )
}

export default App
