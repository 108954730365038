import {initializeApp} from "firebase/app"
import {getFirestore} from "firebase/firestore"
import {getStorage} from "firebase/storage"

import { firebaseConfig } from "./apiKeys"

const firebaseApp = initializeApp(firebaseConfig)  // eslint-disable-line @typescript-eslint/no-unused-vars

const db = getFirestore()
const storage = getStorage(firebaseApp)

export {db, storage}