import React, {useContext} from "react"
import styled from "styled-components"
import Moment from "react-moment"
import ShowRunnerContext from "../../utils/ShowrunnerContext"
import Clock from "react-live-clock"
import ConfigContext from "../../utils/ConfigContext";

// TODO: Change font to Lexend as soon as they support tabular nums *angry*

export default function Time() {
    const CONFIG = useContext(ConfigContext)
    const SR = useContext(ShowRunnerContext)

    return (
        <Wrapper>
            <StyledSection>
                {SR.logo && <Logo src={CONFIG.logoUrl} alt="Reuth Logo" />}
                <StyledDate locale="de" format="dddd[, der] DD. MMMM yyyy" data-testid="date" />
                <Clock format={"HH:mm:ss"} ticking={true} timezone={SR.data.timezone} />
            </StyledSection>
        </Wrapper>
    )
}

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
`

const Logo = styled.img`
  height: 20vh;
  padding-bottom: 2rem;
`

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20rem;
  font-family: Roboto, sans-serif;
`

const StyledDate = styled(Moment)`
  font-size: 5rem;
`