import React from "react"
import { Doughnut } from 'react-chartjs-2';


export default function DoughnutChart(props) {
    console.log(props.content)
    /*props.content = {
        'title': 'Produktivität KW 10',
        'legend': 'false',
        'data': [
            {
                'name': '59%',
                'value': '59',
                'color': '#ef7900'
            },
            {
                'value': '41',
                'color': '#6b6a6a'
            }
        ]
    }*/

    // dummy data from chartjs documentation:
    let content = {
        labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        datasets: [{
            label: '# of Votes',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)'
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)'
            ],
            borderWidth: 1
        }]
    }

    return <Doughnut data={content} />
}